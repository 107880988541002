import { NotificationKind } from './notifications/types';
import { addError } from './notifications/actions';
import { deletePerform as logout } from './session/actions';

import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { captureException } from '@sentry/react';

import type { ApiError } from '../api/types';
import type { AnyAction } from 'redux';
import type { AxiosError } from 'axios';
import type { PutEffect } from 'redux-saga/effects';
import type { put as sagaPut } from 'typed-redux-saga';


const GENERIC_ERROR = 'Something went wrong, our support team has been notified.';
const NETWORK_ERROR = 'There is a problem with your network connection';
const UNAUTHORISED_ERROR = 'You are not authorised to access this page.';
const NOT_FOUND_ERROR = "Argh, we can't find what you are looking for.";
const UNPROCESSABLE_ENTITY_ERROR = 'An error occurred while processing your request';
const GENERIC_ERROR_SUB = 'If you need assistance please call us on ';
const GENERIC_ERROR_SUB_NUMBER = '1300 980 626';

interface FormatForErrorDisplay {
  // kind?: NotificationKind
  title?: string;
  sentryId?: string;
  message?: string;
}

function mapErrorToMessage(errorMessages: string[] | undefined): string {
  if (errorMessages?.includes('Order can no longer be updated.')) {
    return 'Sorry, order can no longer be updated.';
  }

  if (errorMessages?.includes('Deliver at must be on an available date and time')) {
    return 'Sorry, the time you have selected is no longer available. To continue, please go back and select a new time';
  }
  return errorMessages?.join(' ,') ?? UNPROCESSABLE_ENTITY_ERROR;
}

/**
 *
 * If API returned any error information, format it to be displayed unless
 * @param error - Axios Error
 * @param prices - addError config if API is missing data error notificaiton
 *
 * Returns option value price with basePrice if includeBase
 */
function addApiError(error: AxiosError<ApiError>, config?: FormatForErrorDisplay) {
  const errorData = pickBy(error?.response?.data, identity);

  if (!isEmpty(errorData)) {
    const { errorTitle, errorMessages } = errorData;
    const message = mapErrorToMessage(errorMessages);
    const title = errorTitle;

    return addError(message, { title, ...config });
  }

  const message = config?.message ?? GENERIC_ERROR;

  return addError(message, config);
}

function handleUnauthorised(error: AxiosError<ApiError>) {
  const errorData = pickBy(error.response?.data, identity);

  if (!isEmpty(errorData)) {
    const { errorMessages } = errorData;

    // A 401 (unauthorised) likley means the user's token is expired so return the logout action.
    if (errorMessages?.includes('You are not signed in')) return logout();
  }

  // This is an unidentified 401 error, display generic error message.
  return addError(UNAUTHORISED_ERROR);
}

function* handleAxiosError<E extends AxiosError<ApiError>>(error: E, put: typeof sagaPut) {
  const sentryId = captureException(error);
  switch (error.response?.status) {
    case 401:
      yield* put(handleUnauthorised(error));
      break;
    case 404:
      yield* put(addError(NOT_FOUND_ERROR, { sentryId }));
      break;
    case 422:
      yield* put(addApiError(error, { message: UNPROCESSABLE_ENTITY_ERROR, sentryId }));
      break;
    default:
      yield* put(
        addError(GENERIC_ERROR, { sentryId, subMessage: GENERIC_ERROR_SUB, subMessageNumber: GENERIC_ERROR_SUB_NUMBER })
      );
      break;
  }
}

// Handle all the regular system errors
function* handleStandardError<E extends Error>(error: E, put: typeof sagaPut): Generator<PutEffect<AnyAction>> {
  switch (error.message) {
    case 'Network Error':
      yield* put(addError(NETWORK_ERROR, { kind: NotificationKind.DIALOGUE }));
      break;
    default: {
      const sentryId = captureException(error);
      yield* put(
        addError(GENERIC_ERROR, {
          sentryId,
          kind: NotificationKind.DIALOGUE,
          subMessage: GENERIC_ERROR_SUB,
          subMessageNumber: GENERIC_ERROR_SUB_NUMBER,
        })
      );
      break;
    }
  }
}

export function isAxiosError(error: unknown): error is AxiosError<ApiError> {
  return !!(error as AxiosError).isAxiosError;
}

function* handleError(error: unknown, put: typeof sagaPut, fn: () => Generator<PutEffect<AnyAction>>) {
  if (error instanceof Error) {
    console.error(error);
    switch (error.name) {
      case 'ApiDecodeError':
        // Report ApiDecodeErrors but don't scare the user about something that is likly trivial.
        captureException(error);
        break;
      case 'Error':
        if (isAxiosError(error)) {
          yield* handleAxiosError(error, put);
        } else {
          yield* handleStandardError(error, put);
        }
        break;
      default:
        break;
    }
  }

  yield* fn();
}

export default handleError;
