import { ReferralOption } from '../forms';

import type { DeliveryFormField } from '../orderForms';

export enum LeadSource {
  PAID_SEARCH = 'Paid Search',
  FACEBOOK = 'Facebook',
  REFERRAL = 'Referral',
  OTHER = 'Other',
}

export const leadSourceTransform = {
  [ReferralOption.GOOGLE]: LeadSource.PAID_SEARCH,
  [ReferralOption.FACEBOOK]: LeadSource.FACEBOOK,
  [ReferralOption.FROM_A_FRIEND]: LeadSource.REFERRAL,
  [ReferralOption.OTHER]: LeadSource.OTHER,
};

export enum IdentifyField {
  LEAD_SOURCE_ID = 'lead_source_id',
  ID = 'id',
  EMAIL = 'email',
}

// TODO: Split this up.
export enum TrackingEvent {
  ORDER_COMPLETE = 'New order submitted',
  ORDER_UPDATE = 'update order',
  SELECT_TIMESLOT = 'New order timeslot selected',
  REGISTER_AS_TRADE_BUSINESS = 'User registered as tradie',
  SIGN_IN = 'User signed in',
  SIGN_OUT = 'sign out',
  REGISTER = 'User registered',
  VIEW_TOTAL = 'New order total viewed',
  START_ORDER = 'New order started',
  SELECT_SUBURB = 'New order suburb selected',
  VIEW_PRICE = 'New order price viewed',
  VIEW_PRODUCTS = 'New order products viewed',
  VIEW_SITE = 'New order site viewed',
  VIEW_AVAILABILITY = 'New order availability viewed',
  VIEW_ORDER_BILLING = 'New order billing viewed',
  VIEW_ORDER_COMPLETE = 'New order complete viewed',
  SELECT_PRODUCT_TYPE = 'New order product type selected',
  START_REGISTRATION = 'start registration',
  VERIFY_MOBILE_NUMBER = 'verify mobile number',
  VIEW_UPCOMING_ORDERS = 'Upcoming orders viewed',
  VIEW_DELIVERED_ORDERS = 'Past orders viewed',
  VIEW_ORDER = 'Order viewed',
  VIEW_PROFILE = 'User profile viewed',
  VIEW_REFERRALS = 'view referrals',
  VIEW_TRADE_LANDING = 'view trade landing page',
  TRADE_LANDING_CTA_1 = 'click trade landing cta1',
  TRADE_LANDING_CTA_2 = 'click trade landing cta2',
  TRADE_LANDING_CTA_3 = 'click trade landing cta3',
  TRADE_LANDING_CTA_QUOTE = 'click trade landing quote cta',
  VIEW_TRADE_LANDING_MODAL = 'view trade landing modal',
  START_MODIFYING_ORDER = 'Order modification started',
  COMPLETE_MODIFYING_ORDER = 'Order modification submitted',
  COMPLETE_MODIFYING_ORDER_SUBMITTING = 'Order modification submitting',
  CANCEL_ORDER = 'Order cancellation started',
  CONFIRM_CANCEL_ORDER = 'Order cancellation submitted',
  VIEW_CONTACT_SUPPLIER_MODAL = 'Contact supplier button pressed',
  PRESS_CALL_SUPPLIER = 'Contact button pressed',
  PRESS_PAY_INVOICE = 'Order invoice viewed',
  VIEW_INVOICE = 'Order invoice viewed',
  TRADE_PRICING_BTN = 'click trade landing primary cta',
  PAGE_NOT_FOUND = '404 page viewed',
  REFERRAL_LAND = 'Referral page viewed',
  REFERRAL_SHARE = 'Referral link shared',
  REFERRAL_REWARDS = 'Referral rewards viewed',
  REFERRAL_SEND_REMINDER = 'Referral reminder sent',
  REFERRAL_REGISTER_TRADIE = 'Registered via referral',
  REFERRAL_REFERRAL_VERIFY = 'Registration mobile verified',
  REFERRAL_ALREADY_JOINED = "you've already joined (referral)",
  REFERRAL_REGISTRATION_VIEWED = 'Referral registration page viewed',
  WEB_API = 'Web Api Called',
  WEB_API_ERROR = 'Web Api Error',
  PAGE_METRIC = 'Page Metric',
  TRACE = 'Trace',
  VIEW_BILLING = 'Billing viewed',
  QUOTE_PAGE_PRICING_OR_QUOTE = 'Quote or pricing page',
  QUOTE_PAGE_REGION = 'Quote region page',
  QUOTE_PAGE_MIX = 'Quote mix page',
  QUOTE_PAGE_REGISTER = 'Quote register page',
  QUOTE_PAGE_CODE = 'Quote verification code page',
  NEW_QUOTE_STARTED = 'New quote started',
  NEW_QUOTE_REGION_SELECTED = 'New quote region selected',
  NEW_QUOTE_PRODUCT_SELECTED = 'New quote product selected',
  NEW_QUOTE_CONTACT_DETAILS_ENTERED = 'New quote contact details entered',
  NEW_QUOTE_SUBMITTED = 'New quote submitted',
  NEW_QUOTE_SINGE_JOB_SELECTED = 'New quote single job selected',
  NEW_QUOTE_GENERAL_PRICING_SELECTED = 'New quote general pricing selected',
  CLOSE_QUOTE = 'New quote closed',
  FORM_VALIDATION_ERROR = 'Form field validation error',
  CONCRETE_CALCULATOR_OPENED = 'Concrete calculator opened',
  CONCRETE_CALCULATOR_SHAPE_SELECTED = 'Concrete calculator shape selected',
  CONCRETE_CALCULATOR_CALCULATION_COMPLETED = 'Concrete calculator calculation completed',
  PRICING_PLAN_SEE_MORE_PRESSED = 'Pricing plan tier expanded via see more',
  PRICING_PLAN_REGISTERED_VIEWED = 'Pricing plan registered and viewed',
  AVAILABILITY_LANDING_SUBURB_SELECTED = 'Availability landing suburb selected',
  REGULAR_CONCRETER = 'User onboarded as Regular Concreter',
  HOMEPAGE_VIDEO_DEMO = 'Homepage video demo cta',
}

export enum TrackingEventTrait {
  TOTAL_PRICE_ROUNDED = 'total_price_rounded',
  PRICE_PER_CM = 'price_per_cm',
  SUBURB = 'suburb',
  PRODUCT_TYPE = 'product_type',
  SOURCE = 'source',
  ORDER_ID = 'orderId',
  SUPPLIER_NAME = 'supplierName',
  AUTHENTICATED = 'authenticated',
  PATH = 'path',
}

export enum TrackingLinkEvent {
  PAY_NOW = 'press pay now',
  VIEW_TERMS = 'Terms and conditions link followed',
  VIEW_FAQS = 'FAQ link followed',
  VIEW_CONTACT_US = 'Contact us link followed',
  PRIVACY = 'Privacy policy link followed',
  PLAY_STORE = 'visit play store',
  APP_STORE = 'Visited app store',
  START_REGISTRATION = 'Registration started',
  REFERRAL_TCS = 'Referral terms and conditions link followed',
  REFERRAL_LEARN = 'Referral learn more link followed',
  REFERRED_MATE = 'Refer a mate link followed',
  ONETHREEHUNDRED = '1300 980 626',
  EMAIL = 'mailto:hello@foundconcrete.com.au',
  SUPPORT = 'support link clicked',
  FACEBOOK = 'visit facebook',
  INSTAGRAM = 'visit instagram',
  SERVICE_FEES = 'Service fees link followed',
  HELP_CENTRE = 'Help centre link followed',
  PRICING_PLAN_CONTACT_US = 'Pricing plan contact button pressed',
  REVIEWS = 'Review link followed',
  PRICING = 'Pricing link followed',
  PRICING_PLAN_TAB_SELECTED = 'Pricing plan tab selected',
  AVAILABILITY_LANDING_HERO_REGISTER_CTA = 'Availability landing hero register cta',
  AVAILABILITY_LANDING_BOTTOM_REGISTER_CTA = 'Availability landing bottom register cta',
  NEW_QUOTE_BTN = 'Get a quote button pressed',
  MENU_CHECK_AVAILABILITY = 'Check availability menu button pressed',
  PARTNER_PROGRAM = 'Partner Program link followed',
  HOMEPAGE_CHECK_AVAILABILITY = 'Homepage check availability cta',
  HOMEPAGE_QUOTE_BTN = 'Homepage get a quote button pressed',
}

export enum TrackingEventSource {
  BILLING_SCREEN = 'billing screen',
  SIGN_IN_PAGE = 'sign in page',
  HOME_PAGE = 'home page',
  SIGN_UP = 'sign up',
  QUOTE = 'get a quote',
  ORDER_FLOW = 'order flow',
  TRADE_LANDING = 'trade landing',
  ORDER_HUB = 'order hub',
  NOT_SIGNED_IN = 'not signed in',
  HERO_CTA = 'hero cta',
  MENU_CTA = 'menu cta',
  EXTERNAL = 'external',
}

export type SignInSource =
  | TrackingEventSource.BILLING_SCREEN
  | TrackingEventSource.SIGN_IN_PAGE
  | TrackingEventSource.SIGN_UP;
export type RegistrationSource = TrackingEventSource.HOME_PAGE | TrackingEventSource.SIGN_IN_PAGE;
export type BusinessRegistrationSource =
  | TrackingEventSource.SIGN_UP
  | TrackingEventSource.ORDER_FLOW // akin to "billing screen"
  | TrackingEventSource.TRADE_LANDING;
export type OrderSource = TrackingEventSource.HOME_PAGE | TrackingEventSource.ORDER_HUB;

export type CtaTrackingEvents =
  | TrackingEvent.TRADE_LANDING_CTA_1
  | TrackingEvent.TRADE_LANDING_CTA_2
  | TrackingEvent.TRADE_LANDING_CTA_3;

export interface TrackingEventProps {
  (name: TrackingEvent.VIEW_PRICE, traits: { [TrackingEventTrait.PRICE_PER_CM]: number }): void;
  (name: TrackingEvent.SIGN_IN, traits: { [TrackingEventTrait.SOURCE]: SignInSource }): void;
  (name: TrackingEvent.SELECT_PRODUCT_TYPE, traits: { [TrackingEventTrait.PRODUCT_TYPE]: string }): void;
  (name: TrackingEvent.SELECT_SUBURB, traits: { [TrackingEventTrait.SUBURB]: string }): void;
  (name: TrackingEvent.VIEW_TOTAL, traits: { [TrackingEventTrait.TOTAL_PRICE_ROUNDED]: string }): void;
  (name: TrackingEvent.SELECT_TIMESLOT, traits: { [DeliveryFormField.DELIVERY_AT]: string }): void;
  (name: TrackingEvent.START_REGISTRATION, traits: { [TrackingEventTrait.SOURCE]: RegistrationSource }): void;
  (
    name:
      | TrackingEvent.REGISTER_AS_TRADE_BUSINESS
      | TrackingEvent.VERIFY_MOBILE_NUMBER
      | TrackingEvent.START_ORDER
      | TrackingEvent.VIEW_ORDER
      | TrackingEvent.VIEW_TRADE_LANDING,
    traits: { [TrackingEventTrait.SOURCE]: TrackingEventSource }
  ): void;
  (
    name:
      | TrackingEvent.START_MODIFYING_ORDER
      | TrackingEvent.COMPLETE_MODIFYING_ORDER
      | TrackingEvent.CANCEL_ORDER
      | TrackingEvent.CONFIRM_CANCEL_ORDER
      | TrackingEvent.PRESS_PAY_INVOICE
      | TrackingEvent.VIEW_INVOICE,
    traits: { [TrackingEventTrait.ORDER_ID]: number | string }
  ): void;
  (
    name: TrackingEvent.VIEW_CONTACT_SUPPLIER_MODAL | TrackingEvent.PRESS_CALL_SUPPLIER,
    traits: {
      [TrackingEventTrait.ORDER_ID]: number | string;
      [TrackingEventTrait.SUPPLIER_NAME]: string;
    }
  ): void;
  (
    name: TrackingEvent.PAGE_NOT_FOUND,
    traits: { [TrackingEventTrait.AUTHENTICATED]: boolean; [TrackingEventTrait.PATH]: string }
  ): void;
  (
    name: Exclude<
      TrackingEvent,
      | TrackingEvent.SELECT_TIMESLOT
      | TrackingEvent.VIEW_TOTAL
      | TrackingEvent.SELECT_SUBURB
      | TrackingEvent.SELECT_PRODUCT_TYPE
      | TrackingEvent.SIGN_IN
      | TrackingEvent.VIEW_PRICE
      | TrackingEvent.START_REGISTRATION
      | TrackingEvent.REGISTER_AS_TRADE_BUSINESS
      | TrackingEvent.VIEW_PRICE
      | TrackingEvent.VERIFY_MOBILE_NUMBER
      | TrackingEvent.START_ORDER
      | TrackingEvent.VIEW_TRADE_LANDING
      | TrackingEvent.START_MODIFYING_ORDER
      | TrackingEvent.COMPLETE_MODIFYING_ORDER
      | TrackingEvent.CANCEL_ORDER
      | TrackingEvent.CONFIRM_CANCEL_ORDER
      | TrackingEvent.VIEW_CONTACT_SUPPLIER_MODAL
      | TrackingEvent.PRESS_CALL_SUPPLIER
      | TrackingEvent.PRESS_PAY_INVOICE
      | TrackingEvent.VIEW_INVOICE
      | TrackingEvent.PAGE_NOT_FOUND
    >,
    traits?: any
  ): void;
}

export interface TrackLinkProps {
  (htmlLink: HTMLElement, name: TrackingLinkEvent, traits?: any): void;
}
