
import * as R from 'ramda';
import { CustomerField } from 'foundshared/src/api/profile/codecs';
import { createSelector } from 'reselect';

import type { Customer , Profile } from 'foundshared/src/api/profile/types';
import type { AppState } from '../reducer';


export const profileState$ = (state: AppState) => (state.profile.id ? (state.profile as Profile) : null);

export const billingAddress$ = createSelector(
  [profileState$],
  R.pathOr<Customer['billingAddress'] | undefined>(undefined, ['customer', 'billingAddress'])
);

export const basePrice$ = createSelector(
  [profileState$],
  R.pathOr<number | undefined>(undefined, ['customer', 'basePrice'])
);

export const customer$ = createSelector([profileState$], (profile) => profile?.customer);

export const customerTerms$ = createSelector([customer$], (customer) => customer?.creditTerms);

export const creditLimit$ = createSelector(
  [profileState$],
  R.pathOr<Customer['creditLimit'] | undefined>(undefined, ['customer', 'creditLimit'])
);

export const eligibleToMakeReferrals$ = createSelector(
  [customer$],
  (customer) => customer?.[CustomerField.REFERRAL_ELIGIBLE] || false
);

export const referralProgramEligible$ = createSelector(
  [customer$],
  (customer) => customer?.[CustomerField.REFERRAL_PROGRAM_ELIGIBLE] || false
);

export const referralBonus$ = createSelector(
  [customer$],
  (customer) => customer?.[CustomerField.REFERRAL_BONUS]
);

export const customerReferred$ = createSelector(
  [customer$],
  (customer) => customer?.[CustomerField.REFERRAL_RECEIVED] || false
);

/** The pricing tier the logged-in customer is on. */
export const customerPricingTierId$ = createSelector(
  [customer$],
  (customer) => customer?.[CustomerField.PRICING_TIER_ID] || undefined
);

/** @remarks Be sure that it is this that you want and not the base price inside a catalogue */
export const customerBasePrice$ = createSelector([customer$], (customer) => customer?.[CustomerField.BASE_PRICE]);
